import { functions } from "./firebase";
import { httpsCallable } from "firebase/functions";

// functions.httpsCallable("createCustomer") ---> returns callable functions
const shopify = {
  createCustomer: httpsCallable(functions, "createCustomer"),
  updateCustomer: httpsCallable(functions, "updateCustomer"),
  deleteCustomer: httpsCallable(functions, "deleteCustomer"),
  inviteCustomer: httpsCallable(functions, "inviteCustomer"),
  newCompanyAccess: httpsCallable(functions, "newCompanyAccess"),
  updateCompanyAccess: httpsCallable(functions, "updateCompanyAccess"),
  deleteCompanyAccess: httpsCallable(functions, "deleteCompanyAccess"),
  exportUsers: httpsCallable(functions, "exportUsers"),
  deleteExport: httpsCallable(functions, "deleteExport"),
  getRlsDimensions: httpsCallable(functions, "fetchRlsDims"),
  removeRlsAccessFromClient: httpsCallable(functions, "removeRlsAccessFromClient"),
  grantRlsAccessToClient: httpsCallable(functions, "grantRlsAccessToClient"),
  linkShopifyStore: httpsCallable(functions, "install"),
  resyncAccess: httpsCallable(functions, "resyncAccess"),
};

export { shopify };
