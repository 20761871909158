import { useContext, useState } from "react";
import { APIContext } from "../../APIContext";

export default function ResyncPage() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const context = useContext(APIContext);

  const startResync = async () => {
    console.log("Starting resync...");
    setIsLoading(true);
    const response = await context.resyncAccess();
    console.log(response);
    setIsLoading(false);
  };

  return (
    <div style={{ fontFamily: "Montserrat, sans-serif", marginTop: "3rem" }}>
      <div className="m0 signup-form-inner">
        <h1 style={{ fontWeight: "bold" }}>Resync</h1>
        <p>This page is used to trigger a hard-resync between UMA and Shopify. Metrics are printed to console.</p>
        <button className="btn btn-primary" disabled={isLoading} onClick={startResync}>
          {isLoading ? "Loading" : "Start Resync"}
        </button>
      </div>
    </div>
  );
}
